import React, { Component } from "react"
import SignupBanner from "../../images/signup-banner.png"
import SignupCheck from "./SignupCheck/SignupCheck"
import SignupChoose from "./SignupChoose/SignupChoose"
import SignupStep2 from "./SignupSteps/SignupStep2"
import SignupStep3 from "./SignupSteps/SignupStep3"
import SignupStep4 from "./SignupSteps/SignupStep4"
import SignupSuccessPopupModal from "./SignupSuccessPopupModal"
import toaster from "../../utils/toaster"
import swal from "../../utils/swal";
import { authenticationService } from "../../services/basis/AuthenticationService"
import { Translation } from "react-i18next"
import GoBackButton from "../../components/UI/Buttons/GoBackButton"

export default class Signup extends Component {
    state = {
        step: 0,
        profile: null,
        type: null,
        data: null,
        show: false,
        isLoading: false
    }

    setType = (type) => {
        this.setState({
            type: type,
            step: 1
        })
    }

    setShow = (showPopup) => {
        this.setState({ show: showPopup })
    }

    nextStep = data => {
        this.setState({
            data: { ...this.state.data, ...data },
            step: this.state.step + 1
        })
    }

    signupCheck = data => {
        authenticationService.signupCheck(data, this.state.type)
            .then(response => {
                this.setState({
                    profile: response.data,
                    step: 2,
                    data: { ...data, ...response.data, ...response.data.address },
                });
            }).catch((error) => {
                toaster.error(error.message);
            })
    }

    onSubmit = data => {
        this.setState({ isLoading: true })
        const signupData = { ...this.state.data, ...data };
        authenticationService.signup(signupData, this.state.type)
            .then(response => {
                console.log(response);
                this.setState({
                    data: signupData,
                    show: true,
                    isLoading: false
                })
            }).catch((error) => {
                this.setState({
                    isLoading: false
                })
                if (error.errorCode === 13) {
                    swal.error("Password non valida");
                } else {
                    swal.error(error.message);
                }
            })
    }

    render() {
        const { profile, step, type, show, data } = this.state;
        return (
            <Translation>
                {t =>
                    <div className="signup">
                        <div className="signup-banner" style={{ backgroundImage: `url(${SignupBanner})` }} alt="">
                            {/* <div className="text text-center">
                                <span>{t("signup.registerToAccessServices")}</span>
                            </div> */}
                        </div>
                        {step !== 0 &&
                            <>
                                <GoBackButton goBack={() => this.setState({ step: this.state.step - 1 })}>
                                    Torna indietro
                                </GoBackButton>
                                <div className="signup-title">
                                    {type === "fetch" ? "Registrati per accedere ai nostri servizi" : "Registrazione nuova fidelity card"}
                                </div>
                                <div className="checkout-header">
                                    <div className="d-flex justify-content-between checkout-controls">
                                        <span
                                            className={`${step === 1 ?
                                                "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                            {t("signup.header.registry")}
                                        </span>
                                        <span
                                            className={`${step === 2 ?
                                                "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                            {t("signup.header.addresses")}
                                        </span>
                                        <span
                                            className={`${step === 3 ?
                                                "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                            {t("signup.header.account")}
                                        </span>
                                        <span
                                            className={`${step === 4 ?
                                                "checkout-controls-step-current" : "checkout-controls-step-completed"} 
                                        checkout-controls-step`}>
                                            {t("signup.header.consents")}
                                        </span>
                                    </div>
                                </div>
                            </>
                        }
                        {step === 0 && <SignupChoose setType={this.setType} />}
                        {step === 1 && <SignupCheck type={type} signupCheck={this.signupCheck} />}
                        {step === 2 && <SignupStep2 user={profile} data={data} type={type} nextStep={this.nextStep} />}
                        {step === 3 && <SignupStep3 user={profile} data={data} nextStep={this.nextStep} />}
                        {step === 4 && <SignupStep4 user={profile} data={data} signup={this.onSubmit} isLoading={this.state.isLoading} />}
                        <SignupSuccessPopupModal show={show} setShow={this.setShow} />
                    </div>
                }
            </Translation>
        );
    }
}