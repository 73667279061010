import React from "react";
import CookieConsent from "react-cookie-consent";
import Facebook from "../../../images/social/facebook-social.svg";
import GooglePlus from "../../../images/social/google-plus-social.svg";
import Youtube from "../../../images/social/youtube-social.svg";
import Instagram from "../../../images/social/instagram-social.svg";
import Twitter from "../../../images/social/twitter.svg";
import { ReactComponent as Logo } from '../../../images/logo.svg';
import { ReactComponent as Phone } from '../../../images/phone-footer.svg';
import { ReactComponent as Pin } from '../../../images/pin-footer.svg';
import { ReactComponent as Mail } from '../../../images/mail-footer.svg';
import { Translation } from "react-i18next";

import "./footer.styles.scss";

export default class Footer extends React.Component {
    render() {
        const tac = window.tarteaucitron;
        return (
            <Translation>
                {t =>
                    <div className="footer">
                        {/* <CookieConsent
                            location="bottom"
                            buttonText="OK"
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                            expires={150}>
                            {t("footer.cookieConsent")}
                        </CookieConsent> */}
                        <div className="footer-content">
                            <div className="row container-fluid">
                                <div className="col-lg-4 col-12 text-center text-lg-left mobile-col logo">
                                    {/* <Logo /> */}
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/chi-siamo"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.whoWeAre")}
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/parla-con-noi/contattaci"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.contactUs")}
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/mondo-fidelity/si-special-card"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.fidelityWorld")}  </a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/programma-di-affiliazione"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.affiliationPgm")}</a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="/app/privacy"
                                            rel="noopener noreferrer" className="footer-links">
                                            Informativa Privacy
                                        </a>
                                    </div>
                                    <div className="footer-contacts">
                                        <div className="contacts-title">
                                            Hai bisogno di aiuto? Contattaci!
                                        </div>
                                        <div className="element">
                                            <Phone />
                                            <span>800 026 167</span>
                                        </div>
                                        <div className="element">
                                            <Mail />
                                            <a href="mailto:laspesaacasa@cedimarche.it">laspesaacasa@cedimarche.it</a>
                                        </div>
                                        <div className="contacts-subtitle">
                                            I nostri operatori sono presenti nei seguenti orari: <br />
                                            dal Lunedì al Venerdì 8.30 - 12.30 | 14.30 - 18.00
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12 text-center text-lg-left mobile-col">
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/i-prodotti-vale"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.valuableProducts")}
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/offerte/tutte-le-offerte"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.offers")}
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/news"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.news")}
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        {/* <a href="http://www.siconte.it/it/punti-vendita/tutti-i-punti-vendita"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.pointOfSales")}  
                                        </a> */}
                                        <a href="https://laspesaacasa.siconte.it/resources/01/FAQ-Domande_frequenti_La_Spesa_a_casa.pdf"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.faq")}
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="https://laspesaacasa.siconte.it/resources/01/CONDIZIONI_DI_VENDITA_ver_23_02_2021.pdf"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            Condizioni generali di vendita
                                        </a>
                                    </div>
                                    <div className="footer-link">
                                        <span style={{ cursor: "pointer" }} onClick={() => tac.userInterface.openPanel()} className="footer-links">
                                            Gestione cookies
                                        </span>
                                    </div>


                                    {/* <div className="footer-link">
                                        <a href="http://www.siconte.it/it/programma-di-affiliazione"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.affiliationPgm")}</a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/i-prodotti-vale"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.valuableProducts")}
                                        </a>
                                    </div> */}
                                    {/* <div className="footer-link">
                                        <a href="http://www.siconte.it/it/parla-con-noi/newsletters"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.newsLetter")} </a>
                                    </div>
                                    <div className="footer-link">
                                        <a href="http://www.siconte.it/it/sconto-spesa-vale"
                                            rel="noopener noreferrer"
                                            target="_blank" className="footer-links">
                                            {t("footer.discountShopping")}
                                        </a>
                                    </div> */}

                                </div>
                                {/* <div className="col-lg-4 col-12 text-center text-lg-left mobile-col">
                                    <div className="footer-text">
                                        <div className="credits">
                                            <span>Si Supermercati CE.DI.MARCHE SOC. COOP. A.R.L.</span>
                                        </div>
                                    </div>
                                    <div className="footer-text">
                                        {t("footer.home")}
                                    </div>
                                    <div className="footer-text">
                                        {t("footer.home")}
                                    </div>
                                    <div className="footer-text">
                                        {t("footer.home")}
                                    </div>
                                    <div className="footer-social">
                                        <a href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Facebook} alt="facebook" />
                                        </a>
                                        <a href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={GooglePlus} alt="facebook" />
                                        </a>
                                        <a href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Youtube} alt="facebook" />
                                        </a>
                                        <a href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Twitter} alt="facebook" />
                                        </a>
                                        <a href=""
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Instagram} alt="facebook" />
                                        </a>
                                    </div>
                                </div> */}
                                <div className="col-lg-4 col-12 text-lg-left mobile-col">
                                    <div className="footer-text">
                                        <div className="credits">
                                            <span>
                                                {t("footer.visitOur")}
                                                <a
                                                    href="http://www.siconte.it"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                > {t("footer.institutionalWebSite")} </a>
                                                Si Supermercati CE.DI.MARCHE SOC. COOP. A.R.L.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="footer-text">
                                        <div style={{ width: "20px" }}>
                                            <Pin />
                                        </div>
                                        <div className="ml-4 d-flex flex-column">
                                            Via Leonardo da Vinci, 5/7 - 60020
                                            Piane di Camerata Picena (AN)
                                        </div>
                                    </div>
                                    <div className="footer-text">
                                        <div className="d-none d-lg-block">
                                            P.Iva: 01265740421
                                        </div>
                                        {/* <div style={{ width: "20px", marginRight: "20px" }}>
                                            <Phone />
                                        </div> */}
                                        {/* <div className="">
                                            <div className="">
                                                071 947121
                                            </div>
                                            <div className="mt-4 d-none d-lg-block">
                                                P.Iva: 01265740421
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="footer-text d-lg-none">
                                        P.Iva: 01265740421
                                    </div>
                                    <div className="footer-social">
                                        <a href="https://www.facebook.com/SiSupermercati/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Facebook} alt="facebook" />
                                        </a>
                                        {/* <a href="https://www.google.com/search?q=Via+Leonardo+da+Vinci%2C+5%2F7+60020&oq=Via+Leonardo+da+Vinci%2C+5%2F7+60020&aqs=chrome..69i57&sourceid=chrome&ie=UTF-8#lrd=0x132d9d210f080435:0x3320f5211847af2,1,,,"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={GooglePlus} alt="facebook" />
                                        </a> */}
                                        <a href="https://www.youtube.com/user/sisupermercati"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Youtube} alt="facebook" />
                                        </a>
                                        {/* <a href="https://twitter.com/sisupermercati"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Twitter} alt="facebook" />
                                        </a> */}
                                        <a href="https://www.instagram.com/sicontesupermercati/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="footer-social-icon"
                                        >
                                            <img src={Instagram} alt="facebook" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom row no-gutters">
                            <div className="col-12 col-sm-8 text-center text-sm-left">
                                {/* <span>
                                    © {new Date().getFullYear()} {t("footer.privacyPolicy")} {t("footer.copyRight")}
                                </span> */}
                            </div>
                            <div className="col-12 col-sm-4 powered text-center text-sm-right">
                                <span>
                                    {t("footer.poweredBy")} <span className="bold">SmartBip</span>
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </Translation>
        );
    }
}