import React from "react"
import Placeholder from "../../../images/product-placeholder.png"
import { ReactComponent as LandingCart } from '../../../images/landing-cart-sticky.svg';
import { ReactComponent as SlistIcon } from "../../../images/slist.svg"
import { ReactComponent as MxN } from "../../../images/promo MXN.svg"
import { connect } from "react-redux"
import { history } from "../../../App";
import { withRouter } from "react-router-dom";
import config from "../../../config/config";
import { Translation } from "react-i18next";
import * as actionCreators from "../../../redux/store/actions/index"
import renderPrice from "../../../utils/renderPrice";

import "./card-product.styles.scss";

class CardProduct extends React.Component {
    renderPrices = (item) => {
        if (item.price !== item.netPrice) {
            return (
                <nobr>
                    <span className="text-strike strike">
                        {`${renderPrice(item.price.toFixed(2))} \u20AC`}
                    </span>
                </nobr>
            );
        } else {
            return <span></span>
        }
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                const isNxM = item.promotions[0].valueType === "ITEMS";
                let percentage = parseInt(Math.abs(((item.netPrice - item.price) / item.price) * 100));
                return (
                    <Translation>
                        {t =>
                            isNxM ?
                                <div className="card-product-promo" style={{ backgroundColor: '#008ad1' }}>
                                    <span style={{fontSize: '14px', textTransform: 'none'}}>OFFERTA 2x1</span>
                                </div>
                                :
                                <div className="card-product-promo">
                                    {item.netPrice !== item.price ?
                                        <span>{t("cardProduct.offer")} -{percentage}%</span>
                                        :
                                        <span>{t("cardProduct.offer")}</span>
                                    }
                                </div>

                        }
                    </Translation>
                );
            } else {
                return null;
            }
        }
    }

    isAlreadyBooked = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        let path = `/${item.categoryName}/${item.subCategoryName}/${item.description}/${item.barcode}`;
        path = path.replace(/\s/g, '-')
        if (this.props.cart.items) {
            const items = this.props.cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: path,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
            return;
        }

        if (isAlreadyBooked) {
            const addItem = { ...itemToAdd, available: true }
            history.push({
                pathname: path,
                state: { product: addItem, isDetail: true }
            })
        } else {
            if (item.purchaseUm === "KG") {
                history.push({
                    pathname: path,
                    state: { product: item }
                })
            } else {
                this.props.store(this.props.cart.id, item.barcode, item.umStep, item.um);
            }
        }
    }

    checkIsBooked = () => {
        const { cart, item } = this.props;
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === item.ref) {
                    isAlreadyBooked = true;
                }
            });
        }
        return isAlreadyBooked;
    }

    goToDetails = (item) => {
        var isAlreadyBooked = false;
        var itemToAdd = null;
        var newDescription;
        newDescription = item.description.replace('/', '%2F');
        let path = `/${item.categoryName}/${item.subCategoryName}/${newDescription}/${item.barcode}`;
        // let path = `/${item.categoryName}/${item.subCategoryName}/${item.description}/${item.barcode}`;
        path = path.replace(/\s/g, '-')
        const { cart, from, category, categoryId, tag, mainCategory, categoryTitle, subCategory, activeKey, isTag } = this.props;
        if (cart && cart.items) {
            const items = cart.items;
            for (var i = 0; i < items.length; i++) {
                if (items[i].ref === item.ref) {
                    isAlreadyBooked = true;
                    itemToAdd = items[i];
                    break;
                }
            }
        } else {
            history.push({
                pathname: path,
                state: { product: item, from, category, categoryId, tag, mainCategory, categoryTitle, subCategory, activeKey, isTag }
            })
            return;
        }

        if (isAlreadyBooked) {
            history.push({
                pathname: path,
                state: {
                    product: itemToAdd,
                    isDetail: true,
                    from,
                    category,
                    mainCategory,
                    categoryTitle,
                    subCategory,
                    tag,
                    activeKey,
                    isTag
                }
            })
        } else {
            history.push({
                pathname: path,
                state: { product: item, from, category, mainCategory, categoryTitle, subCategory, tag, activeKey, isTag }
            })
        }
    }

    render() {
        const { item, user, currentSlist, cart, setProductToAdd, showLogin } = this.props;
        return (
            <div className="card-product" style={{ ...this.props.style }}>
                <div className={"card-product-container"}>
                    <div className={"card-product-image"}>
                        <div className="link" onClick={() => this.goToDetails(item)}>
                            <img src={item.imageUrl ? item.imageUrl : Placeholder} alt=""
                                onError={(e) => {
                                    e.target.src = Placeholder
                                }}
                            />
                        </div>
                    </div>
                    {this.renderPromo(item)}
                </div>
                <div className="card-product-details">
                    <div className="link" onClick={() => this.goToDetails(item)}>
                        <span>{item.description}</span>
                    </div>
                </div>
                <div className="card-product-um">
                    {item.pricePerUm && item.umPerUm &&
                        `${renderPrice(item.pricePerUm.toFixed(2))} \u20AC / ${item.umPerUm.toLowerCase()} `
                    }
                </div>
                <div className="card-product-prices">
                    {this.renderPrices(item)}
                    {/* <span className="discount">{`-2.00 \u20AC`}</span> */}
                    <nobr><span className="netTotal">{`${renderPrice((item.netPrice * item.umStep).toFixed(2))} \u20AC`}</span></nobr>
                </div>

                <div className="card-product-buttons row">
                    {config.ENABLED_SLIST &&
                        <div className="col-4 col-sm-2">
                            {user &&
                                <button className="slist cta"
                                    onClick={() => currentSlist && this.props.itemCreate(currentSlist.id, item.barcode, 1)}>
                                    <SlistIcon />
                                </button>
                            }
                            {!user &&
                                <button className="slist cta"
                                    onClick={() => {
                                        document.getElementById('user').click();
                                    }}>
                                    <SlistIcon />
                                </button>
                            }
                        </div>

                    }</div>
                <Translation>
                    {t =>
                        <div className={`${config.ENABLED_SLIST ? "col-4 col-sm-10" : "col-12"}`}>
                            {user && cart &&
                                <button
                                    style={{
                                        backgroundColor: this.checkIsBooked() ? "#4fc269" : "#e22018", width: config.ENABLED_SLIST ? "" : "100%",
                                        marginLeft: config.ENABLED_SLIST ? "" : "unset"
                                    }}
                                    className="cart cta"
                                    // onClick={() => cart && store(cart.id, item.barcode, 1, item.um)}>
                                    onClick={() => cart && this.isAlreadyBooked(item)}>
                                    <LandingCart />
                                    <span className="d-none d-sm-inline">{this.checkIsBooked() ? "NEL CARRELLO" : t("cardProduct.buy")}</span>
                                </button>
                            }
                            {user && !cart &&
                                <button
                                    style={{
                                        backgroundColor: this.checkIsBooked() ? "#4fc269" : "#e22018", width: config.ENABLED_SLIST ? "" : "100%",
                                        marginLeft: config.ENABLED_SLIST ? "" : "unset"
                                    }}
                                    className="cart cta"
                                    onClick={() => {
                                        setProductToAdd(item, 1);
                                        this.props.history.push("/cart")
                                    }}>
                                    <LandingCart />
                                    <span className="d-none d-sm-inline">{this.checkIsBooked() ? "NEL CARRELLO" : t("cardProduct.buy")}</span>
                                </button>
                            }
                            {!user &&
                                <button
                                    style={{
                                        backgroundColor: this.checkIsBooked() ? "#4fc269" : "#e22018", width: config.ENABLED_SLIST ? "" : "100%",
                                        marginLeft: config.ENABLED_SLIST ? "" : "unset"
                                    }}
                                    className="cart cta"
                                    onClick={() => {
                                        setProductToAdd(item, 1);
                                        showLogin();
                                    }}>
                                    <LandingCart />
                                    <span className="d-none d-sm-inline">{this.checkIsBooked() ? "NEL CARRELLO" : t("cardProduct.buy")}</span>
                                </button>
                            }
                        </div>

                    }
                </Translation>
            </div>
        );
    }
}

const buttonStyle = {
    width: config.ENABLED_SLIST ? "" : "100%",
    marginLeft: config.ENABLED_SLIST ? "" : "unset",
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        showLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardProduct));