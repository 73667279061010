import React from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Placeholder from "../../images/product-placeholder.png";
import CounterInput from "../../components/UI/Counter/CounterInput";
import ProductInfo from "./ProductInfo/ProductInfo";
import GoBackButton from "../../components/UI/Buttons/GoBackButton";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import { productsService } from "./Service";
import * as actionCreators from "../../redux/store/actions/index";
import config from "../../config/config";
import { withRouter } from 'react-router-dom';
import { format, parseISO } from "date-fns";
import renderPrice from "../../utils/renderPrice";
import ImageGallery from 'react-image-gallery';
import { productClick } from "../../components/GoogleTagManager"
import toaster from "../../utils/toaster";
class Product extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            umStep: null,
            info: null,
            isFavorite: false,
            available: true,
            product: null,
            productImages: null,
            incremented: false,
            decremented: false
        }
    }

    componentDidMount() {
        const barcode = this.props.match.params.barcode;
        const isDetail = this.props.location.state ? this.props.location.state.isDetail : null;
        const detailProduct = this.props.location.state ? this.props.location.state.product : null;
        const storeId = this.props.cart && this.props.cart.storeId ? this.props.cart.storeId : "";
        productsService.fetchByBarcode(barcode, storeId).then(response => {
            const product = response.data;
            console.log(product);
            productClick(product);
            let initialAmount = null;
            // check if is a cart item 
            if (detailProduct) {
                if (isDetail) {
                    // TODO usare il product che arriva dal location state
                    if (detailProduct.um === "PZ") {
                        initialAmount = detailProduct.amount;
                    } else {
                        initialAmount = detailProduct.weight;
                    }
                } else {
                    initialAmount = detailProduct.umStep;
                }
            } else {
                initialAmount = product.umStep;
            }
            // check if is a favorite item 
            let isFavorite = false;
            if (this.props.favorites) {
                this.props.favorites.forEach(item => {
                    if (this.props.match.params.barcode === item.barcode) {
                        isFavorite = true;
                    }
                });
            }

            this.setState({
                product: product,
                amount: initialAmount,
                umStep: product.umStep,
                isFavorite: isFavorite,
                available: isDetail ? true : product.available,
                productImages: product && product.images
                    ?
                    product.images.map(item => {
                        return { original: item }
                    })
                    : null
            })


        }).catch((error) => {
            console.log(error);
        })
        productsService.info(barcode).then(response => {
            if (response.data) {
                this.setState({
                    info: response.data,
                })
            }
        }).catch((error) => {
            console.log(error);
        })
    }

    renderPromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                // const label = item.promotions[0].valueType === "UNKNOWN" ? "promo.unknown" : "promo";
                return (
                    <Translation>
                        {t =>
                            <div className="product-detail-promotion">
                                {t("product.promotionDetails")}{format(parseISO(item.promotions[0].validTo), "dd/MM")}
                            </div>
                        }
                    </Translation>
                );
            } else {
                return null;
            }
        }
    }

    renderPercentagePromo = (item) => {
        if (item.promotions) {
            if (item.promotions[0] !== null && item.promotions[0] !== undefined) {
                const isNxM = item.promotions[0].valueType === "ITEMS";
                let percentage = parseInt(Math.abs(((item.netPrice - item.price) / item.price) * 100));
                return (
                    <Translation>
                        {t =>
                            isNxM ?
                                <div className="card-product-promo" style={{ backgroundColor: '#008ad1', position: 'unset', paddingLeft: '5px' }}>
                                    <span style={{ fontSize: '14px', textTransform: 'none' }}>OFFERTA 2x1</span>
                                </div>
                                :
                                <div className="card-product-promo" style={{ position: 'unset', paddingLeft: '5px' }}>
                                    {item.netPrice !== item.price ?
                                        <span>{t("cardProduct.offer")} -{percentage}%</span>
                                        :
                                        <span>{t("cardProduct.offer")}</span>
                                    }
                                </div>

                        }
                    </Translation>
                );
            } else {
                return null;
            }
        }
    }

    checkIsBooked = () => {
        const { cart } = this.props;
        const { product } = this.state;
        var isAlreadyBooked = false;
        if (cart && cart.items) {
            cart.items.forEach(element => {
                if (element.ref === product.ref) {
                    isAlreadyBooked = true;
                }
            });
        }
        return isAlreadyBooked;
    }

    incrementAmount = () => {
        let newAmount = this.state.amount + this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.product && this.state.product.maxAmount) {
            if (newAmount <= this.state.product.maxAmount) {
                this.setState({ amount: newAmount, incremented: true })
            } else {
                toaster.standard("Limite massimo acquistabile raggiunto")
            }
        } else {
            this.setState({ amount: newAmount, incremented: true })
        }
    }

    decrementAmount = () => {
        let newAmount = this.state.amount - this.state.umStep;
        newAmount = newAmount.toFixed(2);
        newAmount = parseFloat(newAmount);
        if (this.state.amount > this.state.umStep) {
            this.setState({ amount: newAmount, decremented: true })
        }
    }

    renderPrices = (item) => {
        let result;
        if (item.price !== item.netPrice) {
            result =
                <>
                    <span className="mr-1 text-strike product-price">
                        {`${renderPrice((item.price * this.state.amount).toFixed(2))} \u20AC`}
                    </span>
                    <span className="primary-color product-price ml-4">
                        {`${renderPrice((item.netPrice * this.state.amount).toFixed(2))} \u20AC`}
                    </span>
                </>
        } else {
            result = <span
                className="product-price">{`${renderPrice((item.netPrice * this.state.amount).toFixed(2))} \u20AC`}</span>
        }
        return result
    }

    setFavorite = () => {
        const barcode = this.props.match.params.barcode;
        if (this.state.isFavorite) {
            this.props.deleteFavorite(barcode);
        } else {
            this.props.addFavorite(barcode);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.favorites.length - this.props.favorites.length === 1) {
            this.setState({
                isFavorite: true
            })
        }
        if (this.props.favorites.length - nextProps.favorites.length === 1) {
            this.setState({
                isFavorite: false
            })
        }
    }

    goPrevPath = () => {
        if (this.props.location.state) {
            const { from, product, category, categoryId, tag, mainCategory, categoryTitle, subCategory, activeKey, isTag } = this.props.location.state;
            console.log(from);
            if (from === "product") this.props.history.push({
                pathname: `/category`,
                state: {
                    activeKey,
                    categoryId: categoryId,
                    tag: tag,
                    category,
                    mainCategory,
                    categoryTitle,
                    subCategory,
                    from,
                    isTag
                }
            })
            else this.props.history.goBack()
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        const { product, info, productImages, incremented, decremented } = this.state;
        const { user, cart, store, setProductToAdd, showLogin } = this.props;
        if (product) {
            return (
                <Translation>
                    {t =>
                        <>
                            <div className="text-left container-fluid mobile-view-search">
                                <Row>
                                    <Col className="p-0" sm={12} md={12} lg={7} style={{ backgroundColor: "#ffffff" }}>
                                        <div>
                                            <GoBackButton
                                                goBack={() => this.goPrevPath()}>
                                                {t("goBack")}
                                            </GoBackButton>
                                            <div style={{ marginBottom: "49px", paddingTop: "unset" }}
                                                className="view-container">
                                                <div className="product-detail">
                                                    {!productImages &&
                                                        <div className="product-detail">
                                                            <img src={product.imageUrl ? product.imageUrl : Placeholder} alt=""
                                                                onError={(e) => { e.target.src = Placeholder }}
                                                            />
                                                        </div>
                                                    }
                                                    {productImages &&
                                                        <ImageGallery
                                                            items={productImages}
                                                            showFullscreenButton={false}
                                                            autoPlay={false}
                                                            // showBullets={productImages.length > 1 ? true : false}
                                                            showBullets={true}
                                                            showPlayButton={false}
                                                            showThumbnails={false}
                                                            onErrorImageURL={Placeholder}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col className="p-0" style={{ backgroundColor: "#fbfbfb" }} sm={12} md={12} lg={5}>
                                        <div className="product-container">
                                            <div className="product-detail-ref">C. EAN: {product.barcode}</div>
                                            <h1 className="product-detail-name cart-product-description">{product.description}</h1>
                                            {product.pricePerUm && product.umPerUm &&
                                                <h4 className="product-detail-netPrice">
                                                    {`${renderPrice(product.pricePerUm.toFixed(2))} \u20AC / ${product.umPerUm.toLowerCase()} `}
                                                </h4>
                                            }
                                            {this.renderPercentagePromo(product)}
                                            {this.renderPromo(product)}
                                            {product.price &&
                                                <div style={{ marginBottom: "25px" }}>
                                                    <div className="product-detail-heading">{t("price")}</div>
                                                    {this.renderPrices(product)}
                                                </div>
                                            }
                                            <CounterInput
                                                value={this.state.amount}
                                                um={product.purchaseUm}
                                                increment={this.incrementAmount}
                                                decrement={this.decrementAmount}
                                            />
                                            {(product.purchaseUm && product.purchaseUm === "KG") ?
                                                <div className="product-detail-pieces">
                                                    {t("averagePieces", {
                                                        pieces: (this.state.amount / this.state.umStep).toFixed(0),
                                                        label: (this.state.amount / this.state.umStep).toFixed(0) > 1 ? "pezzi" : "pezzo"
                                                    })}
                                                </div>
                                                :
                                                <div className="product-detail-pieces">
                                                    &nbsp;
                                                </div>
                                            }
                                            {user && cart &&
                                                <div className="mb-3">
                                                    <button className="cta"
                                                        style={{ backgroundColor: this.checkIsBooked() ? incremented || decremented ? "#e22018" : "#4fc269" : "#e22018" }}
                                                        onClick={() => {
                                                            cart && store(cart.id, product.barcode, this.state.amount, product.um);
                                                            this.setState({ incremented: false, decremented: false })
                                                        }}>
                                                        <span>{this.checkIsBooked() ? incremented || decremented ? "AGGIORNA CARRELLO" : "GIÀ NEL CARRELLO" : t("buy")}</span>
                                                    </button>
                                                </div>
                                            }
                                            {user && !cart &&
                                                <div className="mb-3">
                                                    <button className="cta"
                                                        onClick={() => {
                                                            setProductToAdd(product, this.state.amount);
                                                            this.props.history.push("/cart")
                                                        }}>
                                                        <span>{t("buy")}</span>
                                                    </button>
                                                </div>
                                            }
                                            {!user &&
                                                <div className="mb-3">
                                                    <button className="cta"
                                                        onClick={() => {
                                                            setProductToAdd(product, this.state.amount);
                                                            showLogin();
                                                        }}>
                                                        <span>{t("access")}</span>
                                                    </button>
                                                </div>
                                            }
                                            {config.ENABLED_SLIST &&
                                                <div>
                                                    {user &&
                                                        <div className="mb-3">
                                                            <button
                                                                className="custom-slist-button outline"
                                                                onClick={() => {
                                                                    this.props.itemCreate(this.props.currentSlist.id, product.barcode, 1)
                                                                }}>
                                                                {t("smartlist.add")}
                                                            </button>
                                                        </div>
                                                    }
                                                    {!user &&
                                                        <div className="mb-3">
                                                            <button
                                                                className="custom-slist-button outline"
                                                                onClick={() => {
                                                                    // setProductToAdd(product, this.state.amount);
                                                                    document.getElementById('user').click();
                                                                }}>
                                                                {t("smartlist.add")}
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <ProductInfo info={info} />
                        </>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        cart: state.cart.cart,
        favorites: state.slists.favorites,
        currentSlist: state.slists.currentSlist,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        store: (cartId, barcode, amount, um) => dispatch(actionCreators.store(cartId, barcode, amount, um)),
        setProductToAdd: (item, amount) => dispatch(actionCreators.setProductToAdd(item, amount)),
        getFavorites: () => dispatch(actionCreators.getFavorites()),
        addFavorite: (barcode) => dispatch(actionCreators.addFavorite(barcode)),
        deleteFavorite: (barcode) => dispatch(actionCreators.deleteFavorite(barcode)),
        itemCreate: (listId, barcode, amount) => dispatch(actionCreators.itemCreate(listId, barcode, amount)),
        showLogin: () => dispatch(actionCreators.showLogin(true)),
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Product))